import Countdown from "../hooks/countdwon";

function  WeddStatus(props) {
    const targetDate = new Date(props.weddTime);
    const dateToday = new Date().getTime();
    return(
      (dateToday>targetDate)
      ?    
      <div className="flex flex-col justify-center items-center  text-2xl mt-4 text-center">
        <h2 className="text-4xl mt-4 font-['Nas']">
          {props.finishTitle}
        </h2>
        <p className="text-lg">
          {props.finishSubtitle}
        </p>
        <div className="text-lg text-center my-4">
          <span class="">{props.date}</span><i class="circle"></i><span class=" ">{props.time}</span>
            <br/>
          <span class=" ">{props.location}</span>
        </div>
      </div>
      : 
      <div className="flex flex-col justify-center items-center">
        <Countdown targetDate={targetDate} />
        <h2 className="text-4xl mt-9 font-['Nas']">
          {props.startTitle}
        </h2>
        <div className="text-lg text-center my-4">
          <span class="">{props.date}</span><i class="circle"></i><span class=" ">{props.time}</span>
            <br/>
          <span class=" ">{props.location}</span>
        </div>
      </div>
    )
}

export default WeddStatus;
