import Confetti from "react-confetti";
import useWindowSize from "@rooks/use-window-size";

function ConfettiSection() {
    const { width, height } = useWindowSize();
    return(
      <div className="fixed top-0 left-0 w-full h-full ">
        <Confetti
        width={width} 
        height={height} 
        numberOfPieces={200} 
        gravity={0.02} 
        colors={['#eed0ad']} 
        opacity={0.3} 
        run={true} 
        tweenDuration={50000}
        drawShape={ctx => {
          ctx.beginPath()
          ctx.moveTo(0, -3)
          ctx.lineTo(3, 0)
          ctx.lineTo(0, 3)
          ctx.lineTo(-3, 0)
          ctx.closePath()
          ctx.stroke()
          ctx.fill()
          }} 
          />
      </div>
    )
}

export default ConfettiSection;
