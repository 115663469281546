import arrowGo from '../assets/icons/arrowGo.svg'
import googleMap from '../assets/icons/googleMap.svg';
// import balad from '../assets/icons/balad.svg';
import waze from '../assets/icons/waze.svg';
import neshan from '../assets/icons/neshan.svg';

function Navigation() {
    const locations = [
        {
            href: 'https://goo.gl/maps/zbYwWtyXdVf9C4J37',
            title: ' گوگل‌مپ',
            icon: googleMap
        },
        {
            href: 'https://nshn.ir/14_bv_UJOx5Qjk',
            title: 'نشان',
            icon: neshan
        },
        {
            href: 'waze://?place=ChIJg644HOHtjT8R8iVSmYZRH38&ll=35.73673540%2C51.07405540&navigate=yes',
            title: ' ویز',
            subtitle: 'با فیلترشکن باز کنید',
            icon: waze
        }
    ];

    return (
        locations.map((location) => (
            <a className='!z-50 block my-2 transition duration-300 hover:scale-105' href={location.href}>
                <div className='flex justify-between items-start py-4 px-4 bg-gradient-to-r from-[#5a715c] to-[#4c543e] text-white rounded-xl drop-shadow-lg'>
                    <img className='w-6 h-12' src={arrowGo} alt='' />
                    <div className='flex'>
                        <div className='flex flex-col justify-center text-right mx-2'>
                            <h4 className='text-xl '>
                                {location.title}
                            </h4>
                            {location.subtitle ? <small className='text-sm text-white'>{location.subtitle}</small> : ''}
                        </div>
                        <img className='w-12 h-12' src={location.icon} alt='' />
                    </div>
                </div>
            </a>
        )))
}

export default Navigation;
