import React, { useState, useEffect } from 'react';
import moment from 'moment';
import persianJs from 'persianjs';

const Countdown = ({ targetDate }) => {
  const [countdown, setCountdown] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const target = moment(targetDate);
      const duration = moment.duration(target.diff(now));
      
      if (duration.asMilliseconds() <= 0) {
        clearInterval(interval);
      } else {
        const days = persianJs(Math.floor(duration.asDays()).toString()).englishNumber().toString();
        const hours = persianJs(duration.hours().toString()).englishNumber().toString();
        const minutes = persianJs(duration.minutes().toString()).englishNumber().toString();
        const seconds = persianJs(duration.seconds().toString()).englishNumber().toString();
        
        setCountdown({ days, hours, minutes, seconds });
      }
    }, 1000);
    
    return () => clearInterval(interval);
  }, [targetDate]);
  
  const { days, hours, minutes, seconds } = countdown;
  
  return (
    <div className="countdown flex items-center justify-center">
      <div className="countdown-item mx-4">
        <div className="countdown-time">{days || '۰'}</div>
        <div className="countdown-label">روز</div>
      </div>
      <div className="countdown-item mx-2">
        <div className="countdown-time">{hours || '۰'}</div>
        <div className="countdown-label">ساعت</div>
      </div>
      <div className="countdown-item mx-2">
        <div className="countdown-time">{minutes || '۰'}</div>
        <div className="countdown-label">دقیقه</div>
      </div>
      <div className="countdown-item mx-4">
        <div className="countdown-time">{seconds || '۰'}</div>
        <div className="countdown-label">ثانیه</div>
      </div>
    </div>
  );
};

export default Countdown;