import React from 'react';

const WordPressEmbed = () => {
  return (
    <iframe
      className='z-50 rounded-xl'
      title="WordPress Embed"
      src="https://wedina.ir/wp/paryaali14030707/"
      width="100%"
      height="600"
      frameBorder="20"
      allowFullScreen
    />
  );
};

export default WordPressEmbed;
