import React from 'react';
import '.././index.css';
import WeddStatus from "./wedd-status";
import  SimpleText from './simple-text';
import  Navigation from './navigations';
import BackgroundMusic from './bg-music';
import WordPressEmbed from './wordpress';

function BeforeParty() {
  return (
    <>
      <WeddStatus
        weddTime={'2024-09-28T19:00:00'}
        startTitle={'مانده تا جشن شادمانه'}
        finishTitle={'بماند به یادگار'} 
        finishSubtitle={''}
        date={'شنبه ۷ مهر ۱۴۰۳'} 
        time={'ساعت ۱۹:۰۰'} 
        location={'عمارت مجلل روژانو'} 
      />

      {/* <SimpleText 
        text={'آغاز می‌کنیم زیستن را، پیوستن و یکی شدن را، لطف حضور شما مایه شادی ما خواهد بود'} 
        class={'!z-50 text-lg text-center mt-4 mb-2 py-4'}
      /> */}

      {/* <BackgroundMusic /> */}
      <SimpleText 
        text={'مسیریابی با'} 
        class={'text-xl font-bold text-right mt-6'}
      />

      <Navigation/>
      <SimpleText 
        text={'گرمدره، بلوار امیرآباد، کوهک پنجم، پلاک ۳۷'} 
        class={'!z-50 text-lg text-center mt-4 mb-2 py-4'}
      />
      <WordPressEmbed/>
      <div className='z-50 py-6 flex flex-col mx-2 gap-2'>
            {/*<a href='https://www.instagram.com/studio_mojtabaarshad' class="w-full inline-flex items-center justify-center leading-4 rounded-md border border-[#eed0ad] px-3 py-4 text-lg text-[#a77c49] transition duration-300 hover:scale-105">
               <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
              </svg> 
              استودیو مجتبی ارشد
            </a>*/}
          <div className='flex gap-2'>
            {/* <a href='https://www.instagram.com/saeidaghaei.music' class="w-1/2 inline-flex items-center justify-center leading-4 rounded-md border border-[#ce7a20] bg-[#ce7a20] px-2 py-4 text-sm  text-white transition duration-300 hover:scale-105">
               <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
              </svg> 
              اِس وان موزیک
            </a> */}
            {/* <a href='https://www.instagram.com/tashrifat_khateratemandegar' class="w-1/2 inline-flex items-center justify-center leading-4 rounded-md border border-[#ce7a20] bg-[#ce7a20] px-2 py-4 text-sm  text-white transition duration-300 hover:scale-105">
               <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
              </svg> 
              تشریفات ناهیدوش
            </a> */}
          </div>

          <a className='flex text-gray-800  text-center items-center justify-center gap-1 transition duration-300 hover:scale-105' href='https://wedina.ir/'>
            {/* <img src={Dikado} alt='dikado'/> */}
            قدرت گرفته از پلتفرم ودینا 
          </a>
        </div>
    </>
  );
}

export default BeforeParty;
