import React from 'react';
import './index.css';
import ConfettiSection from './components/confetti';
import LogoType from './components/logo-type';
import MetaTag from './components/meta-tag';
import BeforeParty from './components/before-party';
// import AfterParty from './components/after-party';
// import ReactAudioPlayer from 'react-audio-player';
// import Sound from 'react-sound';
// import TheMusic from './assets/1.mp3'
// import BackgroundMusic from './components/bg-music';
import GeoLocationNotifier from './components/GeoLocationNotifier'; 
import { Toaster } from 'react-hot-toast';

function App() {

  return (
    <>
      <Toaster toastOptions={{ className: 'my-toast' }} position="top-center" reverseOrder={false} />

      <MetaTag
        title={"Parya & Ali — 1403.07.07"}
        description={"We look forward to meeting you!"}
        cover={"%PUBLIC_URL%/cover.jpg"}
      />

      <section className="!z-40 bg-scroll flex flex-col p-4 mx-auto my-0 w-[34rem] max-w-full ">
        <div className='!z-50 background-container text-right'>
          <LogoType />
        </div>
        <BeforeParty />

        {/* <AfterParty/> */}

        <ConfettiSection />
      </section>

      <GeoLocationNotifier /> 

    </>
  );
}


export default App;
