import { useEffect, useRef } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const GeoLocationNotifier = () => {
    const hasNotified = useRef(false);

    useEffect(() => {
        if (hasNotified.current) return; // اگر Toast قبلاً نشان داده شده، اجرا نشود

        const fetchLocation = async () => {
            try {
                const response = await axios.get('https://ipapi.co/json/');
                const country = response.data.country_name;
                console.log('کاربر از کشور:', country);

                if (country !== 'Iran') {
                    toast('برای عملکرد بهتر، فیلترشکن را خاموش کنید'); // نمایش Toast اگر کشور کاربر ایران نباشد
                    hasNotified.current = true; // علامت‌گذاری برای جلوگیری از نمایش دوباره
                }
            } catch (error) {
                console.error('خطا در دریافت اطلاعات موقعیت:', error);
            }
        };

        fetchLocation();

        // Cleanup function
        return () => {
            hasNotified.current = true;
        };
    }, []); // وابستگی خالی برای اجرای یک بار

    return null;
};

export default GeoLocationNotifier;
