import typo from '../assets/typo.svg'

function LogoType() {
    return(
    <div className="flex flex-col  justify-left items-start text-2xl mt-4">
      <img className='w-3/4 mb-4 h-auto' src={typo} alt='' />
    </div>
    )
}

export default LogoType;
